// import Headroom from 'headroom.js'
// const svg4everybody = require('../../node_modules/svg4everybody/dist/svg4everybody.min')
const appendAround = require('../../node_modules/fg-appendaround/src/appendAround')
import ScrollMagic from 'scrollmagic'

const sunrays = {
	controller: null,
	scene: null,
	el: {
		doc: document,
		cartCollaterals: document.querySelector('.cart-collaterals'),
		pageTitle: document.querySelector('.page-title'),
		pageHeader: document.querySelector('.top-headers-wrapper'),
		pageFooter: document.getElementById('site-footer'),
		productPage: document.querySelector('.single-product'),
		mobileMainNav: document.querySelector('.mobile-navigation.primary-navigation'),
		shopSidebar: document.querySelector('.shop_sidebar'),
		formLogin: document.querySelector('.woocommerce-form-login'),
		formLoginInputs: document.querySelectorAll('.woocommerce-form-login .woocommerce-Input'),
		editAccount: document.querySelector('.edit-account'),
		editAccountInputs: document.querySelectorAll('.edit-account .woocommerce-Input'),
		duration: null,
		proceedToCheckout: document.querySelector('.wc-proceed-to-checkout'),
		resetPassword: document.querySelector('.woocommerce-ResetPassword'),
		isVariableProduct: document.querySelector('.attribute-swatch'),
		buttonOffCanvas: document.getElementById('button_offcanvas_sidebar_left'),
		filtersOffCanvas: null,
		checkboxesOffCanvas: null
	},
	booleans: {
		resizing: false,
		ticking: false,
	},
	throttle(callback, limit) {
		var wait = false // Initially, we're not waiting
		return function () {
			// We return a throttled function
			if (!wait) {
				// If we're not waiting
				callback.call() // Execute users function
				wait = true // Prevent future invocations
				setTimeout(function () {
					// After a period of time
					wait = false // And allow future invocations
				}, limit)
			}
		}
	},
	debounce(func, wait, immediate) {
		var timeout
		return function () {
			var context = this,
				args = arguments
			var later = function () {
				timeout = null
				if (!immediate) func.apply(context, args)
			}
			var callNow = immediate && !timeout
			clearTimeout(timeout)
			timeout = setTimeout(later, wait)
			if (callNow) func.apply(context, args)
		}
	},
	getProductColumnHeight() {
		return sunrays.el.duration
	},
	listeners: {
		cartScrollObserver() {
			var controller = new ScrollMagic.Controller()
			new ScrollMagic.Scene({
				duration: document.querySelector('.woocommerce-cart-form').getBoundingClientRect().height, // the scene should last for a scroll distance of 100px
				offset: 100, // start this scene after scrolling for 50px
			})
				.setPin(sunrays.el.cartCollaterals) // pins the element for the the scene's duration
				.addTo(controller) // assign the scene to the controller
		},
		filtersScrollObserver() {
			sunrays.controller = new ScrollMagic.Controller()
			sunrays.scene = new ScrollMagic.Scene({
				duration: sunrays.getProductColumnHeight, // the scene should last for a scroll distance of 100px
				offset: 150, // start this scene after scrolling for 50px
			})
			sunrays.scene
				.setPin(sunrays.el.shopSidebar) // pins the element for the the scene's duration
				.addTo(sunrays.controller) // assign the scene to the controller
		},
		updateValueOnQtyChangeSimple($) {
			$(document).on('change', 'input.custom-qty', function (e) {
				val = e.target.value
				$('.add_to_cart_button[name="add-to-cart"]')
					.find('.btn')
					.text(add_to_cart_array.add_to_cart + ' - ' + parseInt(add_to_cart_array.product_price) * val + ' ' + add_to_cart_array.currency_symbol)
			})
		},
		updateValueOnQtyChangeVariable($) {
         
         $('.variations_form').each(function () {
            let val,
            k = 0
            $(this).on('found_variation', function (event, variation) {
               var price = variation.display_price //selectedprice
               val = $('input.custom-qty').val()
               $(document).on('change', 'input.custom-qty', function (e) {
						if (k > 0) {
							val = e.target.value
							$('.single_add_to_cart_button')
								.find('.btn')
								.text(add_to_cart_array.add_to_cart + ' - ' + parseInt(price) * val + ' ' + add_to_cart_array.currency_symbol)
						}
						k++
					})
               $('.single_add_to_cart_button')
						.find('.btn')
						.text(add_to_cart_array.add_to_cart + ' - ' + parseInt(price) * val + ' ' + add_to_cart_array.currency_symbol)
				})
			})
		},
		checkHeaderSticky() {
			if (!sunrays.booleans.ticking) {
				window.requestAnimationFrame(function () {
               if (sunrays.el.shopSidebar) {
						if (document.querySelector('.top-headers-wrapper').classList.contains('sticky')) {
							sunrays.el.duration = document.querySelector('.products').getBoundingClientRect().height - document.querySelector('.shop_sidebar').getBoundingClientRect().height - 140
						} else {
							sunrays.el.duration = document.querySelector('.products').getBoundingClientRect().height - document.querySelector('.shop_sidebar').getBoundingClientRect().height
						}
					}
					sunrays.booleans.ticking = false
				})
				sunrays.booleans.ticking = true
			}
		},
		editAccountForm(e) {
			if (!e.target.value.length) {
				e.target.parentElement.classList.remove('floating-label')
			} else {
				e.target.parentElement.classList.add('floating-label')
			}
		},
		cartUpdated() {
			if (!document.querySelector('.cart-collaterals')) {
				document.querySelector('.col-lg-8.without-sidebar').classList.remove('col-lg-8')
			}
		},
		onLoginPopup() {
			window.setTimeout(function () {
				if (document.getElementById('user_login')) {
					document.getElementById('user_login').addEventListener('input', sunrays.listeners.editAccountForm, false)
				}
			}, 250)
		},
		clickedOffCanvasMenu() {
         sunrays.el.filtersOffCanvas = document.querySelectorAll('.off-canvas-wrapper .prdctfltr_filter:not(.pf_adoptive_hide)')
         for (const productFilter of sunrays.el.filtersOffCanvas) {
            sunrays.el.checkboxesOffCanvas = productFilter.querySelectorAll('label:not(.pf_adoptive_hide)')
            for (const checkboxFilter of sunrays.el.checkboxesOffCanvas) {
               checkboxFilter.addEventListener('click', sunrays.listeners.clickedOffCanvasFilter, false)
				}
			}
		},
		clickedOffCanvasFilter() {
			document.querySelectorAll('.close-button')[2].click()
		},
	},
	appendAround($) {
		$('.site-tools').find('ul').appendAround()
	},
	attachEvents($) {
		// if (this.el.cartCollaterals) this.listeners.cartScrollObserver()
      // const mediaQuery = window.matchMedia('(min-width: 1280px)')
         

      // var handleMediaQueryChange = function (e) {
      //    if (e.matches) {
            
      //       if (sunrays.el.shopSidebar && document.querySelector('.products').getBoundingClientRect().height - document.querySelector('.shop_sidebar').getBoundingClientRect().height > 0) {
		// 			sunrays.el.duration = document.querySelector('.products').getBoundingClientRect().height - document.querySelector('.shop_sidebar').getBoundingClientRect().height
		// 			sunrays.listeners.filtersScrollObserver()
		// 			window.addEventListener('scroll', sunrays.listeners.checkHeaderSticky, false)
		// 		}
		// 	}
		// }
      // mediaQuery.addEventListener("change", handleMediaQueryChange)
      // handleMediaQueryChange(mediaQuery)

		if (this.el.productPage) {
			if (!this.el.isVariableProduct) this.listeners.updateValueOnQtyChangeSimple($)
			if (this.el.isVariableProduct) this.listeners.updateValueOnQtyChangeVariable($)
		}
		if (this.el.editAccount) {
			for (const inputElement of this.el.editAccountInputs) {
				if (inputElement.value.length) {
					inputElement.parentElement.classList.add('floating-label')
				}
				inputElement.addEventListener('input', sunrays.listeners.editAccountForm, false)
			}
		}
		if (this.el.formLogin) {
			for (const inputElement of this.el.formLoginInputs) {
				if (inputElement.value.length) {
					inputElement.parentElement.classList.add('floating-label')
				}
				inputElement.addEventListener('input', sunrays.listeners.editAccountForm, false)
			}
		}
		if (this.el.resetPassword) {
			let woocommerceInput = document.querySelector('.woocommerce-Input')
			woocommerceInput.addEventListener('input', sunrays.listeners.editAccountForm, false)
		}

		if (this.el.proceedToCheckout) {
			this.el.proceedToCheckout.addEventListener('click', sunrays.listeners.onLoginPopup, false)
		}
		if (document.querySelector('.woocommerce-cart')) {
			sunrays.listeners.cartUpdated()
			$(document.body).on('updated_wc_div', sunrays.listeners.cartUpdated)
		}
		if (this.el.buttonOffCanvas) {
			this.el.buttonOffCanvas.addEventListener('click', sunrays.listeners.clickedOffCanvasMenu, false)
		}
	},
   init($) {
      // if (window.location.href.indexOf("wp-admin") == -1) { 
      //    document.addEventListener('contextmenu', (event) => event.preventDefault())
      // }		
		this.attachEvents($)
		this.appendAround($)
		// if (window.grecaptcha) {
		// 	window.grecaptcha.ready(() => {
		// 		if (document.querySelector('.stock.out-of-stock')) {
		// 			let outOfStockInput = document.querySelector('.cwgstock_email')
		// 			if (outOfStockInput.value.length) {
		// 				outOfStockInput.parentElement.classList.add('floating-label')
		// 			}
		// 			outOfStockInput.addEventListener('input', sunrays.listeners.editAccountForm, false)
		// 		}
		// 	})
		// }
	},
}
   
export default sunrays;
